const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0">
      <div className="w-full">
        <div className="bg-white w-full p-4 flex justify-center">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 ">
            © {year}{" "}
            <a href="https://pass-card.app" className="hover:underline">
              Pass
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
