import "./App.css";
import { appAnalytics } from "./config/index";
import { Auth } from "./pages/auth";

const analytics = appAnalytics;
analytics.app.automaticDataCollectionEnabled = true;

function App() {
  return <Auth />;
}

export default App;
