import { QRNormal } from "react-qrbtf";
import Icon from "./../assets/img/logo.png";
import React from "react";

export interface IQrCodeViewprops {
  url?: string | null;
  caption?: string;
  fullSize?: boolean;
  disabled?: boolean;
}

const QrCodeView: React.FC<IQrCodeViewprops> = ({
  url,
  caption,
  fullSize,
  disabled,
}) => {
  const makeCode = (small: boolean) => (
    <QRNormal
      value={url || "https://pass-card.app"}
      className="my-qrcode"
      styles={{ svg: { width: small ? `128px` : "320px" } }}
      type="round"
      size={72}
      opacity={100}
      posType="round"
      otherColor="#000000"
      posColor="#000000"
      icon={Icon}
    />
  );

  return (
    <>
      <div className="flex items-center content-center">
        <div className=" bg-white p-0 rounded-2xl">
          {makeCode(fullSize ? false : true)}
        </div>
      </div>
    </>
  );
};

export default QrCodeView;
