import { SpinnerDotted } from "spinners-react";
import { useContext, useState } from "react";
import { UserContext } from "store/user/userContext";
import { useAuth } from "services/authServer";
import { CardLinks } from "./cardlinks";
import { Header } from "./header";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { Sheet } from "react-modal-sheet";
import QrCodeView from "./../components/qrCodeView";

function MyCard() {
  const { loading, loadingUser } = useAuth();
  const [codeExpanded, setCodeExpanded] = useState(false);
  const { profile, cardInfo, loadingProfile } = useContext(UserContext);
  const [isFolded, setIsFolded] = useState(false);

  const toggleFold = () => {
    setIsFolded(!isFolded);
  };

  if (loading || loadingProfile || loadingUser) {
    return (
      <div className="flex w-screen h-screen">
        <div className="m-auto">
          <SpinnerDotted
            size={50}
            thickness={100}
            speed={100}
            color="#36ad47"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      {profile && (
        <div className="bg-white border rounded-2xl m-2 shadow-2xl max-w-sm w-full">
          <div className="flex justify-end p-4 absolute right-2">
            <button
              type="button"
              className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 w-12 h-12"
              onClick={() => setCodeExpanded(true)}
            >
              <QrCodeIcon className="w-6 h-6" />
            </button>
          </div>

          <div className="p-1 grid">
            <div className="max-w-sm rounded-md min-w-full">
              <div className="grid divide-slate-200 divide-y gap-">
                <Header
                  img={profile.profile}
                  names={profile.general?.full_names}
                  job_title={profile.general?.job_title}
                  company={profile.general?.company}
                />

                <Sheet
                  isOpen={codeExpanded}
                  onClose={() => setCodeExpanded(false)}
                >
                  <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content className="flex flex-col items-center">
                      <div className="flex justify-center items-center max-w-screen-sm space-y-6">
                        <QrCodeView
                          url={cardInfo}
                          fullSize
                          caption="Scan code for my online business card."
                        />
                      </div>
                      <div className="flex justify-center items-center max-w-screen-sm space-y-6 pt-12">
                        <p className="text-sm">
                          Scan code to network with{" "}
                          <span className="font-semibold">
                            {profile?.general?.full_names}
                          </span>
                        </p>
                      </div>
                      <footer className="fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0">
                        <div className="w-full">
                          <div className="bg-white w-full p-4 flex justify-center">
                            <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400 ">
                              Powered by{" "}
                              <span className="font-semibold text-sm">
                                <a
                                  href="https://digital.pass-card.app"
                                  className="hover:underline"
                                >
                                  Pass
                                </a>
                              </span>
                            </span>
                          </div>
                        </div>
                      </footer>
                    </Sheet.Content>
                  </Sheet.Container>

                  <Sheet.Backdrop />
                </Sheet>
              </div>
            </div>
          </div>

          <CardLinks
            email={profile.general?.email}
            phone={profile.general?.phone}
            website={profile.general?.website}
            links={profile.links}
          />
        </div>
      )}
    </div>
  );
}

export default MyCard;
