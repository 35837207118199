interface IntroProps {
  onGo: () => void;
}
const Intro = ({ onGo }: IntroProps) => {
  return (
    <div className="p-8 justify-center items-center flex flex-col content-center">
      <div className="italic font-semibold">
        Welcome to <span>Pass</span>!
      </div>
      <div className="text-black text-sm mt-4 text-center">
        To generate your business card, please fill in your information
      </div>
      <div className="mt-8">
        <img
          src="/img/svg/welcome.svg"
          alt="Pass intro"
          className="h-72 object-contain"
        />
      </div>
      <button
        type="submit"
        className="text-white text-md rounded-md p-2 mt-8 bg-emerald-600 w-fit pl-4 pr-4"
        onClick={onGo}
      >
        Let's go!
      </button>
    </div>
  );
};

export { Intro };
