import { useAuth } from "../../services/authServer";
import { useState } from "react";
import { Modal } from "flowbite-react";
const Nav = () => {
  const { logout } = useAuth();
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <div class="container flex flex-wrap items-center justify-between mx-auto">
        <div class="flex items-center">
          <span class="self-center text-xl font-semibold whitespace-nowrap text-black">
            Account settings
          </span>
        </div>
        <div className="">
          <button
            class="inline-flex border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
            onClick={() => setShowConfirm(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="black"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
              />
            </svg>
          </button>
        </div>
      </div>

      <Modal
        show={showConfirm}
        size="md"
        popup={true}
        dismissible={true}
        onClose={() => setShowConfirm(false)}
      >
        <Modal.Header>
          <div className="text-sm">Confirm</div>
        </Modal.Header>
        <Modal.Body>
          <p className="">Are you sure you want to logout?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-emerald-600 border border-transparent rounded-md hover:bg-emerald-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-emerald-500"
            onClick={logout}
          >
            Logout
          </button>
          <button
            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { Nav };
