import { appDb, fileStorage } from "config";
import { doc, setDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  StorageReference,
} from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { UserContext } from "store/user/userContext";
import { useAuth } from "./../../services/authServer";

export interface IGeneralFormErrors {
  full_names?: string;
  phone?: string;
  email?: string;
}

export interface IOfficeFormErrors {
  employee_id?: string;
}

export interface ISchoolFormErrors {
  student_id?: string;
}

export enum EImageType {
  Profile = "profile",
  CardFront = "card_front",
  CardBack = "card_back",
}

export interface IUserInfoForms {
  general?: {
    full_names?: string;
    job_title?: string;
    company?: string;
    email?: string;
    phone?: string;
    website?: string;
  };
  profile?: string | null;
  business?: {
    card_front?: string;
    card_back?: string;
  };
  office?: {
    employee_id?: string;
  };
  school?: {
    student_id?: string;
  };
  links: {
    facebook?: string;
    x?: string;
    instagram?: string;
    linkedin?: string;
    youtube?: string;
    github?: string;
  };
  private?: boolean;
}

const useInfo = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { profile, getProfile } = useContext(UserContext);
  const [formConfig, setFormConfig] = useState<IUserInfoForms>({
    general: {
      full_names: user?.displayName || "",
      job_title: "",
      company: "",
      email: user?.email || "",
      phone: user?.phoneNumber || "",
      website: "",
    },
    office: {
      employee_id: "",
    },
    school: {
      student_id: "",
    },
    business: {
      card_back: "",
      card_front: "",
    },
    profile: null,
    links: {
      facebook: "",
      x: "",
      instagram: "",
      linkedin: "",
      youtube: "",
      github: "",
    },
  });
  const [openSnackbar] = useSnackbar({ position: "top-center" });
  useEffect(() => {
    if (profile) setFormConfig({ ...formConfig, ...profile });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);
  const onGeneralFormSubmit = (values: object) => {
    saveUserData({ general: values });
  };
  const onOfficeFormSubmit = (values: object) => {
    saveUserData({ office: values });
  };

  const onLinksFormSubmit = (values: object) => {
    saveUserData({ links: values });
  };

  const onSchoolFormSubmit = (values: object) => {
    saveUserData({ school: values });
  };
  const saveUserData = (values: object) => {
    setLoading(true);
    const ref = doc(appDb, "public_profiles", user!.uid);
    setDoc(ref, { ...values }, { merge: true })
      .then(() => {
        setLoading(false);
        getProfile();
        openSnackbar("Saved successfully!", 1000);
      })
      .catch((err) => {
        setError(error);
        console.error(err);
        setLoading(false);
      });
  };
  const showImagePicker = (forId: string) => {
    document.getElementById(forId)?.click();
  };
  const onImageUpload = (type: EImageType, files: FileList | null) => {
    if (files && files.length > 0) {
      saveImage(type, files![0]);
    }
  };

  const saveImage = (type: EImageType, file: File) => {
    setLoading(true);
    const storageRef = ref(
      fileStorage,
      `/profiles/public/${user?.uid}/${type}.jpg`
    );
    uploadBytes(storageRef, file)
      .then(() => {
        getImageUrlForType(storageRef, type);
        setLoading(false);
      })
      .catch((err) => {
        setError(error);
        console.error(err);
        setLoading(false);
      });
  };

  const getImageUrlForType = (ref: StorageReference, type: EImageType) => {
    setLoading(true);
    getDownloadURL(ref)
      .then((url) => {
        saveUserData(getImageProp(type, url));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getImageProp = (type: EImageType, url: string): Object => {
    switch (type) {
      case EImageType.Profile:
        return { profile: url };
      case EImageType.CardFront:
        return { business: { card_front: url } };
      case EImageType.CardBack:
        return { business: { card_back: url } };
      default:
        throw new Error("Image type unknown");
    }
  };

  return {
    loading,
    formConfig,
    onGeneralFormSubmit,
    onOfficeFormSubmit,
    onSchoolFormSubmit,
    onLinksFormSubmit,
    onImageUpload,
    showImagePicker,
  };
};

export { useInfo };
