import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const devConfig = {
  apiKey: "AIzaSyCaShUSrwYHHsngNBN3qZwD-0hTqpPV5pE",
  authDomain: "pass-dev-24a88.firebaseapp.com",
  projectId: "pass-dev-24a88",
  storageBucket: "pass-dev-24a88.appspot.com",
  messagingSenderId: "676808752965",
  appId: "1:676808752965:web:4caf4cda7acf5fcefb482c",
  measurementId: "G-4Z1LQ8GFMM",
};
const prodConfig = {
  apiKey: "AIzaSyCqyt2q_nmFQtjuwbygORa0Kh6Jiszl5wQ",
  authDomain: "digital.pass-card.app",
  databaseURL:
    "https://pass-8d311-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "pass-8d311",
  storageBucket: "pass-8d311.appspot.com",
  messagingSenderId: "647188068246",
  appId: "1:647188068246:web:199f9ab2dbb8de09020fb7",
  measurementId: "G-S303CBD0Z6",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;
const app = initializeApp(config);
const appAnalytics = getAnalytics(app);
const appDb = getFirestore(app);
const fileStorage = getStorage();
const authModule = getAuth(app);
export { appAnalytics, appDb, authModule, fileStorage };
