import { routes } from "pages/home";
import { Navigate, Route, Routes } from "react-router-dom";
import { Nav } from "./../pages/home/nav";
import MyCard from "./mycard";
import Profile from "./../pages/businessCard/index";
import { MyInfo } from "pages/info";
import { Account } from "pages/account";
import { TabNav } from "./../components/tabNav";
import { useContext, useEffect, useState } from "react";
import { Intro } from "./intro";
import { UserContext } from "store/user/userContext";
import { Spinner } from "./loader";
import { motion, AnimatePresence } from "framer-motion";
import {
  Bars3BottomLeftIcon,
  ChevronDoubleLeftIcon,
  CreditCardIcon,
  DocumentTextIcon,
  PowerIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "services/authServer";
import { Modal } from "flowbite-react";
const AppRoutes = () => {
  const [showIntro, setShowIntro] = useState<string | null>();
  const { profile, loadingProfile, userIsNew, userChecked } =
    useContext(UserContext);

  useEffect(() => {
    if (userIsNew) {
      setShowIntro("yes");
    }
  }, [userIsNew]);

  const goToInfo = () => {
    setShowIntro("");
  };

  if (loadingProfile) {
    return (
      <div className="flex flex-col justify-center items-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (profile) {
    return <ProfileRoutes />;
  }

  if (userIsNew && showIntro === "yes") {
    return (
      <div className="">
        <div className="p-4">
          <Nav />
        </div>
        <Intro onGo={goToInfo} />
      </div>
    );
  }

  if (userIsNew && showIntro === "") {
    return (
      <div>
        <div className="p-4">
          <Nav />
        </div>
        <MyInfo />
      </div>
    );
  }

  return <></>;
};

const ProfileRoutes = () => {
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);

  const toggleLeftPanel = () => {
    setIsLeftPanelOpen(!isLeftPanelOpen);
  };
  const menuItems = [
    {
      icon: CreditCardIcon,
      label: "Privacy",
      url: "https://pass-card.app/privacy",
    },
    {
      icon: DocumentTextIcon,
      label: "Terms",
      url: "https://pass-card.app/terms",
    },
  ];
  const { logout } = useAuth();
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <div className="flex h-screen overflow-x-hidden">
        <AnimatePresence initial={false}>
          {isLeftPanelOpen && (
            <motion.div
              key="left-panel"
              initial={{ x: -216 }}
              animate={{ x: 0 }}
              exit={{ x: -216 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="bg-slate-50 h-full w-[216px] overflow-hidden fixed left-0 top-0 z-10 flex flex-col"
            >
              <div className="p-4 flex-grow">
                <div className="text-lg font-semibold mb-6 text-primary">
                  More
                </div>
                <nav>
                  <ul className="space-y-2">
                    {menuItems.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center space-x-3 p-2 rounded-lg hover:bg-accent hover:text-primary transition-colors duration-200 text-primary"
                        >
                          <item.icon className="h-5 w-5" />
                          <span>{item.label}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              <div className="p-4 border-t border-gray-300">
                <button
                  className="flex items-center space-x-3 p-2 w-full rounded-lg hover:bg-accent transition-colors duration-200 text-primary"
                  onClick={() => setShowConfirm(true)}
                >
                  <PowerIcon className="h-5 w-5" />
                  <span>Logout</span>
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          animate={{ marginLeft: isLeftPanelOpen ? "216px" : 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="flex-1 bg-white p-1  w-screen min-w-full"
        >
          <motion.button
            onClick={toggleLeftPanel}
            className="fixed top-0 left-0 -translate-y-1/2 bg-background text-white p-2 rounded-br-2xl shadow-2xl transition-colors duration-200 z-20"
            animate={{
              x: isLeftPanelOpen ? 216 : 0,
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            {isLeftPanelOpen ? (
              <ChevronDoubleLeftIcon className="h-8 w-8" />
            ) : (
              <Bars3BottomLeftIcon className="h-8 w-8" />
            )}
          </motion.button>
          <div className="relative">
            <Routes>
              <Route path={routes.qr} element={<MyCard />} />
              <Route path={routes.card} element={<Profile />} />
              <Route path={routes.myInfo} element={<MyInfo />} />
              <Route path={routes.account} element={<Account />} />
              <Route
                path="/"
                element={<Navigate to={routes.qr} replace={true} />}
              />
            </Routes>
            <TabNav />
          </div>
        </motion.div>
      </div>
      <Modal
        show={showConfirm}
        size="md"
        popup={true}
        dismissible={true}
        onClose={() => setShowConfirm(false)}
      >
        <Modal.Header>
          <div className="text-sm">Confirm</div>
        </Modal.Header>
        <Modal.Body>
          <p className="">Are you sure you want to logout?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-background border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-background"
            onClick={logout}
          >
            Logout
          </button>
          <button
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { AppRoutes };
