import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { register } from "./serviceWorkerRegistration";
import { OnlineStatusProvider } from "services/useOnlineStatus";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <OnlineStatusProvider>
      <App />
    </OnlineStatusProvider>
  </React.StrictMode>
);
register();
reportWebVitals();
