import { FullSpinner } from "../components/loader";
import { useAuth } from "../services/authServer";
import { Home } from "./home";
import { Login } from "./login";

const Auth = () => {
  const { loadingUser, user } = useAuth();
  if (loadingUser) return <FullSpinner />;

  return <>{user ? <Home /> : <Login />}</>;
};

export { Auth };
