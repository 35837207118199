import { useAuth } from "../services/authServer";
import { AppInfo } from "./appInfo";
import logo from "./../assets/img/logo.png";

const Login = () => {
  const { loading, loginWithGoogle, loginWithMicrosoft, loginWithApple } =
    useAuth();

  return (
    <div className="h-screen divide-y">
      <div className="flex divide-x h-full flex-col md:flex-row">
        <div className="flex-1 md:flex hidden items-center justify-center">
          <AppInfo />
        </div>
        <div className="relative flex-1 flex flex-col h-full md:bg-slate-50 items-center py-16 md:py-0 md:justify-center">
          <div className="">
            <img className="mx-auto h-12 w-auto" src={logo} alt="Pass logo" />
            <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
              Pass
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              World's most versatile digital business card.
            </p>
            <div className="text-lg text-center pt-16"> Sign in with</div>
            <div className="flex justify-center gap-4 mt-8">
              <button
                type="button"
                className="text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800"
                disabled={loading}
                onClick={loginWithGoogle}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 488 512"
                  className="w-5 h-5"
                >
                  <path
                    fill="currentColor"
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                  />
                </svg>
              </button>

              <button
                type="button"
                className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800"
                disabled={loading}
                onClick={loginWithMicrosoft}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#ff5722"
                    d="M6 6H22V22H6z"
                    transform="rotate(-180 14 14)"
                  ></path>
                  <path
                    fill="#4caf50"
                    d="M26 6H42V22H26z"
                    transform="rotate(-180 34 14)"
                  ></path>
                  <path
                    fill="#ffc107"
                    d="M26 26H42V42H26z"
                    transform="rotate(-180 34 34)"
                  ></path>
                  <path
                    fill="#03a9f4"
                    d="M6 26H22V42H6z"
                    transform="rotate(-180 14 34)"
                  ></path>
                </svg>
              </button>

              <button
                type="button"
                className="text-gray-500 border border-gray-500 hover:bg-gray-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800"
                disabled={loading}
                onClick={loginWithApple}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0,0,256,256"
                >
                  <g
                    fill="currentColor"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                  >
                    <g transform="scale(5.12,5.12)">
                      <path d="M44.52734,34.75c-1.07812,2.39453 -1.59766,3.46484 -2.98437,5.57813c-1.94141,2.95313 -4.67969,6.64063 -8.0625,6.66406c-3.01172,0.02734 -3.78906,-1.96484 -7.87891,-1.92969c-4.08594,0.01953 -4.9375,1.96875 -7.95312,1.9375c-3.38672,-0.03125 -5.97656,-3.35156 -7.91797,-6.30078c-5.42969,-8.26953 -6.00391,-17.96484 -2.64844,-23.12109c2.375,-3.65625 6.12891,-5.80469 9.65625,-5.80469c3.59375,0 5.85156,1.97266 8.82031,1.97266c2.88281,0 4.63672,-1.97656 8.79297,-1.97656c3.14063,0 6.46094,1.71094 8.83594,4.66406c-7.76562,4.25781 -6.50391,15.34766 1.33984,18.31641zM31.19531,8.46875c1.51172,-1.94141 2.66016,-4.67969 2.24219,-7.46875c-2.46484,0.16797 -5.34766,1.74219 -7.03125,3.78125c-1.52734,1.85938 -2.79297,4.61719 -2.30078,7.28516c2.69141,0.08594 5.47656,-1.51953 7.08984,-3.59766z"></path>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
            <div className="mt-8 flex items-center justify-center">
              <a
                href="mailto:support@pass-card.app"
                className="text-sm text-slate-500"
              >
                Need help?
              </a>
            </div>
          </div>
          <footer className="absolute bottom-0 right-0 left-0 flex flex-col items-center justify-center mb-0">
            <div className="text-sm pl-4 pr-4 pb-4 text-center">
              By continuing, you agree to our{" "}
              <a
                href="https://pass-card.app/terms"
                target="_blank"
                rel="noreferrer"
                className="text-emerald-900 underline"
              >
                terms
              </a>{" "}
              and{" "}
              <a
                href="https://pass-card.app/privacy"
                target="_blank"
                rel="noreferrer"
                className="text-emerald-900 underline"
              >
                privacy
              </a>{" "}
              policies.
            </div>
            <div className="bg-slate-50 w-full p-4 flex">
              <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400 text-center flex w-full justify-center gap-1">
                © {new Date().getFullYear()}{" "}
                <a href="https://pass-card.app" className="hover:underline">
                  Pass
                </a>
                . All Rights Reserved.
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export { Login };
