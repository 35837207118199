import { useLocation, useNavigate } from "react-router-dom";
const useRouteNav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const goTo = (route) => {
    navigate(route, { replace: true });
  };
  return {
    currentRoute: location.pathname,
    goTo,
  };
};

export { useRouteNav };
