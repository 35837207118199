import { TextInput, Label } from "flowbite-react";
import { Formik } from "formik";
import { Spinner } from "./../components/loader";
import { IUserInfoForms } from "pages/info/useInfo";
const Links = ({
  loading,
  formConfig,
  onSubmit,
}: {
  loading: boolean;
  formConfig: IUserInfoForms;
  onSubmit: (links: Object) => void;
}) => {
  return (
    <div className="">
      <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
        Links
      </h5>
      <small className="">
        Add links to your social media accounts such as LinkedIn, etc. Leave a
        field empty for a link you don't want to add.
      </small>
      <Formik
        initialValues={formConfig.links || {}}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 pl-2 pr-2 mt-8"
          >
            <div>
              <div className="mb-2 block">
                <Label htmlFor="linkedIn" value="LinkedIn profile" />
              </div>
              <TextInput
                id="linkedin"
                type="text"
                placeholder="Enter LinkedIn profile Url"
                required={false}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.linkedin}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="x" value="X profile" />
              </div>
              <TextInput
                id="x"
                type="text"
                placeholder="Enter X profile url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.x}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="github" value="GitHub profile" />
              </div>
              <TextInput
                id="github"
                type="text"
                placeholder="Enter GitHub profile url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.github}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="youtube" value="Youtube profile" />
              </div>
              <TextInput
                id="youtube"
                type="text"
                placeholder="Enter Youtube profile url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.youtube}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="instagram" value="Instagram profile" />
              </div>
              <TextInput
                id="instagram"
                type="text"
                placeholder="Enter Instagram profile url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.instagram}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="facebook" value="Facebook profile" />
              </div>
              <TextInput
                id="facebook"
                type="text"
                placeholder="Enter Facebook profile url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.facebook}
              />
            </div>
            <button
              type="submit"
              className="text-secondary text-md rounded-md p-2 mt-4 bg-background"
              disabled={loading}
            >
              {loading ? <Spinner /> : "Update links"}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { Links };
